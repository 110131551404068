import React, { useContext } from "react";
import { FaCaretRight } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";
import BaseLayout from "../global/BaseLayout";
import TransparentHeader from "../global/TransparentHeader";
import { ButtonContent } from '../global/boton/ButtonContent'
import { BiPhoneCall, BiCalendar } from 'react-icons/bi'
import { MdOutlineEmail } from 'react-icons/md'
import { FaRegClock } from 'react-icons/fa'
import CounterUp from '../global/CounterUp'
import Values from "../About/Values";
import Map from "../Contact/MapContent";

function ServicesDetail() {
    const { rpdata } = useContext(GlobalDataContext);
    const { id } = useParams();
    // console.log(id);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <BaseLayout PageName={`${rpdata?.dbPrincipal?.name}`}>
            {
                rpdata?.dbServices?.map((item, index) => {
                    if (item.name.replace(" ", "-").toLowerCase() === id) {
                        return (
                            <div key={index}>
                                <TransparentHeader
                                    // imagen aleatoria
                                    bgimg={
                                        rpdata?.gallery?.length > 1 ?
                                            rpdata?.gallery[Math.floor(Math.random() * rpdata?.gallery?.length)]
                                            : rpdata?.stock[Math.floor(Math.random() * rpdata?.stock?.length)]
                                    }
                                    headertitle={item.name}
                                    Subheader={'Our Services'}
                                />
                                <div className="mainMenu">

                                    <div className="w-[95%] md:w-[90%] mx-auto my-10 block md:flex md:gap-5 z-10 relative">
                                        <div className="w-full md:w-9/12 shadow-lg shadow-neutral-800/30 p-5 md:p-10 mb-5 rounded-tl-[40px] rounded-br-[40px] bg-white">
                                            <img
                                                src={item.description[0].img}
                                                alt='no found'
                                                className="w-full object-cover h-auto md:h-[500px] rounded-tl-3xl rounded-br-3xl"
                                            />
                                            <div className="w-full h-auto flex justify-center text-center">
                                                <h3 className="w-auto h-auto py-3 px-8 bg-2 rounded-tl-3xl rounded-br-3xl -mt-12 text-white">
                                                    {
                                                        item.name
                                                    }
                                                </h3>
                                            </div>
                                            <div className="flex justify-center items-center w-full h-auto pt-10">
                                                <ButtonContent />
                                            </div>
                                        </div>
                                        <div className="w-full md:w-1/4 shadow-lg bgBloqueServicesDetail p-5 md:p-5 mb-5 text-white rounded-tl-3xl rounded-br-3xl ">
                                            <div className="pb-5">
                                                <h4 className="text-center md:text-start"> Our Menu</h4>
                                                <ul className="px-1 md:px-4">
                                                    {
                                                        rpdata?.dbServices?.slice(0, 5).map((nam, index) => {
                                                            return (
                                                                <li key={index}>
                                                                    <Link
                                                                        to={`/${nam.name.replace(" ", "-").toLowerCase()}`}
                                                                        onClick={goToTop}
                                                                        className='flex items-center text-white pb-3'
                                                                    >
                                                                        <FaCaretRight />
                                                                        <span className="pl-2">
                                                                            {nam.name}
                                                                        </span>
                                                                    </Link>
                                                                </li>

                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                            <div className="pb-5">
                                                <h4 className="text-center md:text-start">Contact</h4>
                                                <ul className="px-1 md:px-4">
                                                    {
                                                        rpdata?.dbPrincipal?.phones.map((phone, index) => {
                                                            return (
                                                                <li key={index} className='flex items-center text-white pb-3'>
                                                                    <a
                                                                        href={`tel:+1${phone.phone}`}
                                                                        className='flex items-center'
                                                                    >
                                                                        <BiPhoneCall />
                                                                        <span className="pl-2">
                                                                            {phone.phone}
                                                                        </span>
                                                                    </a>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        rpdata?.dbPrincipal?.emails.map((email, index) => {
                                                            return (
                                                                <li
                                                                    key={index}
                                                                    className='flex items-center text-white pb-3'
                                                                >
                                                                    <a
                                                                        href={`mailto:${email.email}`}
                                                                        className='flex items-center'
                                                                    >
                                                                        <MdOutlineEmail />
                                                                        <span className="pl-2">
                                                                            {email.email}
                                                                        </span>
                                                                    </a>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                            <div className="pb-5">
                                                <h4 className="text-center md:text-start">Opening Hours</h4>
                                                <ul className="px-1 md:px-4">
                                                    <li
                                                        key={index}
                                                        className='flex items-center text-white pb-3'
                                                    >
                                                        <BiCalendar />
                                                        <span className="pl-2">
                                                            {rpdata?.dbPrincipal?.workdays?.[0]?.day}
                                                        </span>
                                                    </li>
                                                    <li
                                                        key={index}
                                                        className='flex items-center text-white pb-3'
                                                    >
                                                        <FaRegClock />
                                                        <span className="pl-2">
                                                            {rpdata?.dbPrincipal?.workHours?.[0]?.hour}
                                                        </span>
                                                    </li>
                                                    <li
                                                        key={index}
                                                        className='flex items-center text-white pb-3'
                                                    >
                                                        <BiCalendar />
                                                        <span className="pl-2">
                                                            {rpdata?.dbPrincipal?.workdays?.[1]?.day}
                                                        </span>
                                                    </li>
                                                    <li
                                                        key={index}
                                                        className='flex items-center text-white pb-3'
                                                    >
                                                        <FaRegClock />
                                                        <span className="pl-2">
                                                            {rpdata?.dbPrincipal?.workHours?.[1]?.hour}
                                                        </span>
                                                    </li>
                                                    <li
                                                        key={index}
                                                        className='flex items-center text-white pb-3'
                                                    >
                                                        <BiCalendar />
                                                        <span className="pl-2">
                                                            {rpdata?.dbPrincipal?.workdays?.[2]?.day}
                                                        </span>
                                                    </li>
                                                    <li
                                                        key={index}
                                                        className='flex items-center text-white pb-3'
                                                    >
                                                        <FaRegClock />
                                                        <span className="pl-2">
                                                            {rpdata?.dbPrincipal?.workHours?.[2]?.hour}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    } else return console.log('Servicio no encontrado')

                })
            }

            <CounterUp image={rpdata?.gallery?.[6]} />

            <div className="mt-[-110px]">
                <Values />
            </div>
            <Map />

        </BaseLayout>
    );
}

export default ServicesDetail;